.press {
  padding-top: 10px;
}

.press .press_text h1 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
} 

.press .press_text h1 span {
  color: #f5973a;
}

.press .press_text hr {
  width: 241px;
  margin: 0 auto 25px;
  border-color: #f5973b;
}
.press-image {
  height: 50vh !important;
}
.press-header {
  height: 12vh !important;
}
.quote {
  text-align: left;
  margin-left: 50px;
  margin-top: 50px;
}

.press .press_text vl {
  border-left: 3px solid rgb(226, 101, 17);
  height: 146px;
  position: absolute;
  margin-left: 40px;
  margin-top: 40px;
}

.press .press_text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 0px;
  font-family: "Lato", sans-serif !important;
}

p {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}

.press .card {
  margin-block-end: 20px;
  align-content: center;
}

.press .card-header {
  background-color: rgb(235, 235, 235) !important;
}
.press .press-card-footer {
  height: 10vh;
}