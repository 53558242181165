.about {
  padding: 25px;
  margin-left: 15px;
}

.about .family-pic {
  background-image: url(/images/about_bgg.png);
  background-size: cover;
  width: 100%;
  margin-left: -20;
}

.black-text {
  color: black !important;
  font-size: 20px;
}

.about .row p {
  color: white;
  font-family: "Lato", sans-serif !important;
  font-size: 15px;
}

.about .about_text h1 {
  text-align: left;
  margin-top: 0px;
  text-transform: uppercase;
}

.about .row h5 {
  text-align: center;
  margin-top: 10px;
  font-family: "Lato", sans-serif !important;
  font-size: 20px;
  color: #ffffff;
}
.about .row h4 {
  text-align: center;
  margin-top: 15px;
  font-family: "Lato", sans-serif !important;
  font-size: 25px;
  color: #ffffff;
  font-weight: 600 !important;
}
.about .about_text h1 span {
  color: #f5973a;
}

.about .about_text hr {
  width: 241px;
  margin: 0 auto 25px;
  border-color: #f5973b;
}

.about .about_text p {
  text-align: left;
  font-size: 19px;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 0px;
  font-family: "Lato", sans-serif !important;
}

.about .row img {
  margin-top: 0px;
  border-radius: 50%;
  vertical-align: middle !important;
}

.carousel-item {
  align-items: center !important;
  text-align: center;
  height: 600px;
  overflow: hidden;
  width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute !important;
  top: -25% !important;
}

p {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}

img {
  vertical-align: middle !important;
}

.carousel-inner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel {
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: left;
}

.profile-circle {
  text-align: center;
}
