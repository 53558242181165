video {
  position: fixed;
  z-index: -1;
}

.carousel-hr {
  background-color: rgb(255, 136, 0);
  height: 2.5px !important;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.carousel-indicators {
  display: none !important;
}
