.termsofuse {
  padding-top: 50px;
}

.termsofuse .termsofuse_text h1 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
}
.termsofuse .termsofuse_text h3 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
  color: #f59739 !important;
  font-size: 32px !important;
}
.termsofuse .termsofuse_text h1 span {
  color: #f5973a;
}

.termsofuse .termsofuse_text hr {
  width: 220px;
  margin: 0 auto 25px;
  border-color: #f5973b;
}

.termsofuse .termsofuse_text p {
  text-align: left;
  font-size: 19px;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 0px;
  font-family: "Lato", sans-serif !important;
}

p {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}
