* {
  font-size: large;
  font-family: "Lato", sans-serif !important;
}

.sharing {
  margin-bottom: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.flex-parent {
  display: flex;
  justify-content: flex-end;
}
.social {
  padding: 2vw;
  margin: 1px;
}
.social-store {
  color: black !important;
}