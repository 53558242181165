* {
  font-size: large;
  font-family: "Lato", sans-serif !important;
}

#collasible-nav-dropdown {
  z-index: 1;
  font-size: 20px;
}

.disabled {
  color: gray !important;
}
