.contact {
  padding-top: 25px;
}

.contact .contact_text h1 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
  color: black;
}
.contact .contact_text h1 span {
  color: #f5973a;
}

.contact .contact_text hr {
  width: 241px;
  margin: 0 auto 25px;
  border-color: #f5973b;
}

.quote {
  text-align: left;
  margin-left: 50px;
  margin-top: 50px;
}

.contact .contact_text vl {
  border-left: 3px solid rgb(226, 101, 17);
  height: 146px;
  position: absolute;
  margin-left: 40px;
  margin-top: 40px;
}

.contact .contact_text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 0px;
  font-family: "Lato", sans-serif !important;
}

p {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}

.btn-primary {
  background-color: #14e001 !important;
  border-color: #14e001 !important;
}

.contact .iframe {
  margin-left: 20px;
}

.contact img {
  align-self: center;
  position: absolute;
  padding-left: 30px;
}
