footer {
  color: white;
  background-color: black;
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -2rem;
  bottom: 1em;
}

a {
  color: white;
  font-size: 1.5vw;
  justify-content: space-around;
}
