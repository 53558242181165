.science {
  padding-top: 50px;
}

ul,
li {
  list-style-type: none; 
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.science .science_text h1 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
  color: black;
}
.science .science_text h1 span {
  color: #f5973a;
}

h6 {
  color: black;
}
.science .science_text h4 {
  text-align: left;
  margin-top: 30px;
  color: #f5973a !important;
  font-weight: bolder;
  font-size: 20px;
}

.science .science_text hr {
  margin: 0 auto 25px;
}

.science .science_text p {
  text-align: left;
  font-size: 19px;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 10px;
  text-shadow: 0px 0px 0px;
  color: black;
  font-family: "Lato", sans-serif !important;
}

p {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}

.summary-text a:hover {
  color: #f5973a !important;
}

.nav-link-1 {
  color: rgb(255, 121, 32);
  text-decoration: none;
  font-size: 19px;
  line-height: 33px;
  font-family: "Lato", sans-serif !important;
}

.nav-link-1:hover {
  transition: all 0.2s ease-out;
  text-decoration: none !important;
}

.list-1 {
  font-size: 20px;
}

.science_text a {
  text-decoration: underline;
}

.science_text a:hover {
  text-decoration: underline !important;
}

.summary-text {
  font-size: 20px !important;
  margin-left: 10px !important;
}
