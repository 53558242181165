.faqs {
  padding-top: 50px;
}

.faqs .faqs_text h1 {
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
}
.faqs .faqs_text h1 span {
  color: #f5973a;
}

.faqs .faqs_text hr {
  width: 241px;
  margin: 0 auto 25px;
  border-color: #f5973b;
}

.quotes {
  text-align: left;
  margin-left: 50px;
  margin-top: 50px;
}

.faqs .faqs_text vl {
  border-left: 3px solid green;
  height: 190px;
  position: absolute;
  margin-left: 40px;
  margin-top: 40px;
}

.faqs .faqs_text p, a {
  font-size: 15px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 8px;
  text-shadow: 0px 0px 0px;
  font-family: "Lato", sans-serif !important;
}

p {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  color: #333;
  font-family: "Lato", sans-serif !important;
}

.card-body img {
  display: flex;
}

.card-header {
  background-color: #f5973a !important;
}

.faqs .card-header {
  margin-bottom: 5px;
}

.btn-link {
  color: ivory !important;
  font-size: 20px !important;
}

.btn {
  display: contents !important;
}
